import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import DrawSvg from "./svg/DrawSvg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  position: relative;
  scroll-snap-align: start;
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
width: 70%
height:200vh;
background-color: ${(props) => props.theme.text};
margin: 0 auto;
display:flex;
justify-content:center;
align-items:center;
position:relative;

@media (max-width: 64em) {
  width: 80%;
}
@media (max-width: 48em) {
  width: 90%;
}
`;
const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background-color:lightblue;

  @media (max-width: 48em) {
    width: 100%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;
    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 0 50px 0 50px;
      text-align: right;
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  }

  & > *:nth-of-type(2n) {
    justify-content: end;
    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }

    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;
const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 35px;

  @media (max-width: 48em) {
    flex: 1;

    width: 100%;
  }
`;
const ItemContainer = styled.div`
  width: 40%;
  height: fit fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.body};

  @media (max-width: 48em) {
    width: 70%;
  }
`;
const Box = styled.p`
  height: fit-content;
  background-color: ${(props) => props.theme.carouselColor};
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
`;
const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  text-align: center;
  font-weight: 600;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;

const SubSubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  text-align: center;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;
const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  text-align: center;

  font-weight: 0;
  margin: 0.5rem 0;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const RoadMapItem = ({ title, subtext, addToRef, subtitle }) => {
  return (
    <Item ref={addToRef}>
      <ItemContainer>
        <Box>
          <SubTitle className="text-lg lg:text-xl">{title}</SubTitle>
          <SubSubTitle className="text-sm lg:text-lg font-normal">
            {subtitle}
          </SubSubTitle>
          {subtext.map((txt, id) => (
            <Text
              key={id}
              className="font-thin whitespace text-xs lg:text-sm break-words"
            >
              {txt}
            </Text>
          ))}
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        {
          y: "0",
        },
        {
          y: "-30%",

          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top center+=200px",
            end: "bottom center",
            scrub: true,
            // markers:true,
          },
        }
      );
    });

    return () => {};
  }, []);

  return (
    <Section id="roadmap">
      <Title className="text-center mx-auto text-white text-[50px] lg:text-[60px] whitespace-nowrap">
        Roadmap
      </Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
    <RoadMapItem
        title="Phase 1: Token Strategy & Partnerships (Month 1-2)"
        subtext={[
            "- Lock 50% of tokens for 6 months. \u2713",
            "- Burn 10% + of total supply. \u2713",
            "- Identify potential partners for early collaboration. \u2713",
            "- Create a strong, collaborative, including community. \u23F3",
            "- Publish a litepaper. \u23F3"
        ]}
    />
    <RoadMapItem
        title="Phase 2: Digital Presence & Listings (Month 1-2)"
        subtext={[
            "- Launch a new user-friendly website. \u2713",
            "- Optimize the project's metrics for CoinMarketCap and CoinGecko listings. \u23F3",
            "- Apply for listings on both platforms. \u23F3",
            "- Finalize website's look to align with brand image. \u23F3",
            "- Peparation of marketing campagne for Phase 3."
        ]}
    />
    <RoadMapItem
        title="Phase 3: Community & Market Growth (Month 1-2)"
        subtext={[
            "- Increase liquidity pool through incentives.",
            "- Boost trading volume with reward programs.",
            "- Launch 'Burning or Gold' marketing campaign.",
            "- Encourage organic community growth through regular updates."
        ]}
    />
    <RoadMapItem
        title="Phase 4: Exchange Listing & Brand Expansion (Month 3)"
        subtext={[
            "- Prepare for listing on a reputable crypto exchange.",
            "- Design and launch ArabPepe merchandise.",
            "- Use exchange listing to attract new investors.",
            "- Create a new roadmap for the next phase based on feedback."
        ]}
    />
</Items>

      </Container>
    </Section>
  );
};

export default Roadmap;
